import React from 'react';
import Routes from './routes/route';
import './css/index.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
